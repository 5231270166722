/* main: style.scss */

// 600, 900, 1200

@mixin rwd($size) {
    @if $size == xs {
        @media (max-width: 599px) { @content; }
    } @else if $size == sm {
        @media (min-width: 600px) { @content; }
    } @else if $size == md {
        @media (min-width: 900px) { @content; }
    } @else if $size == lg {
        @media (min-width: 1200px) { @content; }
    } @else if $size == xlg {
        @media (min-width: 1500px) { @content; }
    }
}
