form {}

input,
textarea,
select {
    margin-bottom: 1rem;
}

input[type="text"],
input[type="number"],
input[type="password"] {
    width: 100%;
    padding: .5rem;
    border: 1px solid #ccc;
}
input[type="file"] {
    padding: .4rem;
    border: 1px solid #a9a9a9;
    width: 100%;
}

textarea {
    width: 100%;
    padding: .5rem;
}

label {
    display: block;
    font-size: .875rem;
    margin-bottom: .5rem;
    color: lighten($color_primary, 20%);
}

input[type="submit"] {
    background-color: $color_orange;
    font-size: .875rem;
    text-transform: uppercase;
    padding: .5rem 1rem;
    border: none;
    color: white;
    font-weight: 700;
}

select {
    width: 100%;
    padding: .5rem;
}

input[type="checkbox"] ~ img {
    border: 2px solid transparent;
}

input[type="checkbox"]:checked ~ img {
    border-color: $color_secondary;
    box-shadow: 0 0 5px $color_secondary;
}
