/// compileCompressed: ../../../../public_html/css/admin.min.css

*,
*::before,
*::after {
    box-sizing: border-box;
}

// Fonts
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
$font_size: 16px;
$font_primary: "Lato", sans-serif;
$font_secondary: "Arial", sans-serif;
$font_awesome: "Font Awesome 5 Free";

// Colors
$color_main: #332e2e;
$color_primary: #332e2e;
$color_secondary: #eba424;
$color_text: #332e2e;
$color_orange: #eba424;
$color_border: lighten($color_main, 70%);
$color_background: lighten($color_main, 90%);
// Alert colors
$color_error: #e74c3c;
$color_success: #2ecc71;

// imports
@import "utilities/normalize";
@import "utilities/rwd";
@import "utilities/grid";
@import "utilities/mixins";
// Layouts
@import "layouts/order";
// Import plugins
@import "plugins/slick";
@import "plugins/slick-theme";

// @import "_form";
@import "_form_admin";
// @import "_navmobile";
// @import "_global";

html,body {
    font-size: $font_size;
    font-family: $font_primary;
    color: $color_text;
    min-height: 100%;
}

body {
    height: 100%;
}

.row {
    @include row;
}

.column {
    @include column;
}

.window-height {
    height: 100%;
}

.container-fluid {
    display: flex;
}

.logo {
    padding: 2rem 1rem;
    text-align: center;
    margin-bottom: 1rem;
    // background-color: white;
    margin: 1rem;
}
.logo--cms {
    padding: 1rem;
}

.nav-column {
    flex: 0 0 300px;
    // background-color: lighten($color_main, 50%);
    background-color: $color_primary;
    position: relative;
    height: 100vh;
    position: sticky;
    top: 0;
    border-right: 1px solid darken(#dad9d9, 10%)
}

.content-column {
    flex: 1 1 100%;
    background-color: #f5f5f5;
}

.content {
    padding: 1rem;
}

.nav {
    margin-top: 1rem;
    ul {
        padding: 0;
        margin: 0 0 0 1rem;
        list-style: none;
        li {
            display: block;
            a {
                display: block;
                color: lighten($color_main, 50%);
                padding: 1rem 1rem;
                text-transform: uppercase;
                text-decoration: none;
                font-size: .75rem;
                font-weight: 700;
                &:hover {
                    background-color: lighten($color_main, 20%);
                    color: white;
                }
                &:before {
                    content: '•';
                    color: lighten($color_main, 50%);
                    margin-right: 1rem;
                }
            }
            ul {
                margin-left: 1rem;
                li {
                    a {
                        &:before {
                            content: '\25A1';
                            color: lighten($color_main, 50%);
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.author {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

// content layout

.section {
    background-color: white;
    border-radius: 3px;
    margin-bottom: 1rem;
}
.section__header {
    display: flex;
    height: 50px;
    padding: 0 1rem;
    border-bottom: 1px solid $color_border;
    align-items: center;
    justify-content: space-between;
}
.section__title {
    font-weight: 700;
    text-transform: uppercase;
}
.section__content {
    padding: 1rem;
    font-size: .875rem;
}
.section__footer {
    padding: 1rem;
    border-top: 1px solid $color_border;
    display: flex;
    &--center {
        justify-content: center;
    }
}
.section__button {
    height: 32px;
    border: 1px solid #999;
    margin-left: .5rem;
    display: flex;
    align-items: center;
    color: #999;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 700;
    text-decoration: none;
    padding: 0 1rem;
}

.data {}
.data__row {
    @include row;
    border-bottom: 1px solid $color_border;
    &:last-child {
        border-bottom: none;
    }
}
.data__column {
    @include column;
    margin-bottom: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
}
.data__link {
    text-decoration: none;
    color: $color_orange;
}
.data__header {
    text-transform: uppercase;
    font-weight: 700;
    color: black;
}

.button {
    background-color: $color_orange;
    font-size: .875rem;
    text-transform: uppercase;
    padding: .5rem 1rem;
    border: none;
    color: white;
    font-weight: 700;
}

.alert {
    border: 2px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 1.125rem;
}
.alert--error {
    border-color: $color_error;
    background-color: lighten($color_error, 35%);
    color: $color_error;
}
.alert--success {
    border-color: $color_success;
    background-color: lighten($color_success, 35%);
    color: $color_success;
}

.photos {
    @include block-grid(3);
    li {
        img {
            margin-bottom: .5rem;
            border: 1px solid #ccc;
        }
        span {
            border-bottom: 1px solid $color_border;
            padding-bottom: .375rem;
            margin-bottom: .375rem;
        }
        .section__button {
            margin: 0 auto 0 0;
        }
    }
}
.photos__link {
    text-transform: uppercase;
    text-decoration: none;
    font-size: .75rem;
    color: #999;
    display: inline-block;
    margin-right: .5rem;
}

#external_url_wrapper {
    display: none;
}

.login-wrapper {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: $color_primary;
}
.section--login {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    background-color: white;
    padding: 1rem;
}

@for $i from 1 through 12 {
    $width: 100% * ($i / 12);
    .column-#{$i} {
        @include column($width)
    }
}

.link--action {
    text-transform: uppercase;
    color: #666;
    text-decoration: none;
    padding: .25rem;
    display: block;
    font-weight: 700;
    font-size: .75rem;
}

.checkbox-label {
    display: inline-block;
    margin-right: .5rem;
}

// Paginacja

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        border: 1px solid transparent;
        padding: .25rem;
        color: gray;
        @include rwd(sm) {
            padding: .5rem;
        }
        @include rwd(md) {
            padding: 1rem;
        }
        &.disabled {
            opacity: .5;
        }
        &.active {
            border: 1px solid $color-orange;
            color: $color-orange;
            font-weight: 700;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
}
