
$gutter: 1rem;
$block-grid-gutter: 0.5rem;
$grid-max-width: 1200px;

*,
*::before,
*::after {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
}

@mixin wrapper {
    width: 100%;
    display: block;
}

@mixin container {
    display: block;
    max-width: $grid-max-width;
    margin: 0 auto;
    padding-left: $gutter;
    padding-right: $gutter;
}

@mixin container-flex {
    @include container;
    display: flex;
}

@mixin row {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    margin-left: -$gutter;
    margin-right: -$gutter;
}

@mixin column($width: auto) {
    @if $width == "auto" {
        width: auto;
        flex: 1;
    }
    @else {
        width: #{$width};
        flex: 0 0 #{$width};
    }
    padding-left: $gutter;
    padding-right: $gutter;
}

@mixin block-grid($n) {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    @include rwd(md) {
        // margin-left: -$gutter;
        // margin-right: -$gutter;
    }
    li {
        width: 100% / $n;
        padding: 0 $gutter / 2;
        // margin-bottom: $block-grid-gutter;
        // display: flex;
    }
}
