@mixin skew_before {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        background-color: inherit;
        height: 100%;
        width: 20px;
        transform: skew(-20deg, 0deg);
    }
}
@mixin skew_after {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        background-color: inherit;
        height: 100%;
        width: 20px;
        transform: skew(-20deg, 0deg);
    }
}
@mixin bg-gradient($color) {
    $color_lighten: lighten($color, 30%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    background-image: $color_lighten;
    background-image: -moz-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: -webkit-gradient(left top, left bottom, color-stop(0%, $color_lighten), color-stop(45%, $color_lighten), color-stop(55%, $color), color-stop(100%, $color));
    background-image: -webkit-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: -o-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: -ms-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: linear-gradient(to bottom, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color_lighten', endColorstr='$color', GradientType=0 );
}
@mixin title-gradient($color) {
    $color_lighten: lighten($color, 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: $color_lighten;
    background-image: -moz-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: -webkit-gradient(left top, left bottom, color-stop(0%, $color_lighten), color-stop(45%, $color_lighten), color-stop(55%, $color), color-stop(100%, $color));
    background-image: -webkit-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: -o-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: -ms-linear-gradient(top, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    background-image: linear-gradient(to bottom, $color_lighten 0%, $color_lighten 45%, $color 55%, $color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color_lighten', endColorstr='$color', GradientType=0 );
}

@mixin button {
    @include bg-gradient($color_primary);
    background-color: $color_primary;
    color: white;
    text-transform: uppercase;
    border-radius: .25rem;
    text-decoration: none;
    font-size: .875rem;
    border: 1px solid rgba($color_primary, .25);
    display: inline-block;
    padding: .375rem .75rem;
    @include rwd(lg) {
        padding: .625rem 1.5rem;
    }
}
