// main: ../app.scss

.basket {
    margin-bottom: 1rem;
}

.order-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1rem;
    li {
        background-color: #ffffff;
        &:nth-of-type(even) {
            // background-color: #f0f0f0;
            background-color: #f9f9f9;
        }
    }
}

.order-item {
    display: flex;
}

.order-item__amount {
    flex: 0 0 36px;
    padding: .5rem;
    text-align: right;
}

.order-item__product {
    flex: 1;
    padding: .5rem;
}

.order-item__product-title {
    margin-top: 0;
    margin-bottom: .5rem;
}

.order-item__additives {
    margin: 0;
    font-size: .875rem;
    color: #666666;
}

.order-item__price {
    flex: 0 1 auto;
    font-weight: 700;
    padding: .5rem;
    text-align: right;
}

.order-item__remove {
    flex: 0 1 auto;
    padding: .5rem;
    a {
        color: red;
        text-decoration: none;
    }
}

.order-summary {
    margin-bottom: 1rem;
}

.order-summary__item {
    display: flex;
    &:last-child {
        border-top: 1px solid black;
        margin-top: .5rem;
    }
}

.order-summary__title {
    @extend .order-item__product;
    font-size: 1rem;
    &--big {
        font-size: 1.25rem;
        font-weight: 700;
    }
}

.order-summary__price {
    @extend .order-item__price;
    font-size: 1rem;
    &--big {
        font-size: 1.25rem;
    }
}

.order-submit {
    background-color: $color_primary;
    color: white;
    border: none;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    padding: .75rem;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.summary-item__title {
    margin-top: 0;
    margin-bottom: .25rem;
}

.summary-item__value {
    margin-top: 0;
    margin-bottom: 2rem;
}
